import { default as indexGnP0twjjrLMeta } from "/app/pages/aboutUs/index.vue?macro=true";
import { default as _91id_936Jasuu92smMeta } from "/app/pages/catalog/category/[id].vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as _91id_93OF54UEP2yxMeta } from "/app/pages/catalog/portfolio/[id].vue?macro=true";
import { default as sub_45category_45_91id_93lgdJWjOEugMeta } from "/app/pages/catalog/sub-category-[id].vue?macro=true";
import { default as coming_45soonp8168ES4xqMeta } from "/app/pages/coming-soon.vue?macro=true";
import { default as indexQ6aaLm6uHyMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as indexUAMGQig0OAMeta } from "/app/pages/create-account/index.vue?macro=true";
import { default as chatsVSS4e6IWhCMeta } from "/app/pages/customer-office/chats.vue?macro=true";
import { default as indexVgdoxsv6GvMeta } from "/app/pages/customer-office/index.vue?macro=true";
import { default as orders_45archiveEVj7ITeu39Meta } from "/app/pages/customer-office/orders-archive.vue?macro=true";
import { default as indexdxOTKoq6k2Meta } from "/app/pages/customer-office/profile/index.vue?macro=true";
import { default as notification_45settings8g1DKPU5SFMeta } from "/app/pages/customer-office/profile/notification-settings.vue?macro=true";
import { default as privacy_45securityZy6UnyTb87Meta } from "/app/pages/customer-office/profile/privacy-security.vue?macro=true";
import { default as profilemZNwzd5ZrrMeta } from "/app/pages/customer-office/profile.vue?macro=true";
import { default as enter73PTfrDZbyMeta } from "/app/pages/dev/enter.vue?macro=true";
import { default as geo_45testm3q4iM8PuwMeta } from "/app/pages/geo-test.vue?macro=true";
import { default as _91article_93q3qbbdo3uPMeta } from "/app/pages/help/[id]/article/[article].vue?macro=true";
import { default as index89kcWQtMUyMeta } from "/app/pages/help/[id]/index.vue?macro=true";
import { default as indexjpdP4BPFKIMeta } from "/app/pages/help/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index4swLnAVeKJMeta } from "/app/pages/jobtoken/index.vue?macro=true";
import { default as chatsjTXTOJh46IMeta } from "/app/pages/master-office/chats.vue?macro=true";
import { default as correct_45userdatad50zt5BoLLMeta } from "/app/pages/master-office/correct-userdata.vue?macro=true";
import { default as indexPzNdXdv9jKMeta } from "/app/pages/master-office/index.vue?macro=true";
import { default as portfoliomoaZtnZiCSMeta } from "/app/pages/master-office/portfolio.vue?macro=true";
import { default as billingHRicchvkV5Meta } from "/app/pages/master-office/profile/billing.vue?macro=true";
import { default as indexPGVBpr34xJMeta } from "/app/pages/master-office/profile/index.vue?macro=true";
import { default as notification_45settingsUyUIzbqidtMeta } from "/app/pages/master-office/profile/notification-settings.vue?macro=true";
import { default as privacy_45securityUPrS9ye6PIMeta } from "/app/pages/master-office/profile/privacy-security.vue?macro=true";
import { default as profileHgy6J17bSdMeta } from "/app/pages/master-office/profile.vue?macro=true";
import { default as promotionhU7cJZwvwxMeta } from "/app/pages/master-office/promotion.vue?macro=true";
import { default as task_45archivex5Gx9AeYf5Meta } from "/app/pages/master-office/task-archive.vue?macro=true";
import { default as _91id_93ecKcpBg7JMMeta } from "/app/pages/master-office/task-search/[id].vue?macro=true";
import { default as _91task_id_93M5iBj4UWv5Meta } from "/app/pages/master-office/task-search/[task_id].vue?macro=true";
import { default as indexsrlcQYEUDEMeta } from "/app/pages/master-office/task-search/index.vue?macro=true";
import { default as order_45createv22DEFCmw9Meta } from "/app/pages/order-create.vue?macro=true";
import { default as _91id_9385VdxFiDQKMeta } from "/app/pages/page/[id].vue?macro=true";
import { default as indext2mji5fLq5Meta } from "/app/pages/privacy-policy/index.vue?macro=true";
import { default as _91id_93nLfBx71BseMeta } from "/app/pages/promo/[id].vue?macro=true";
import { default as indexkwdd20Vk1dMeta } from "/app/pages/promo/index.vue?macro=true";
import { default as thankyouWry34NVec8Meta } from "/app/pages/promo/thankyou.vue?macro=true";
import { default as _91id_93PJQByBNvptMeta } from "/app/pages/tasks/category/[id].vue?macro=true";
import { default as indexejAi8hALgAMeta } from "/app/pages/terms-of-service/index.vue?macro=true";
export default [
  {
    name: "aboutUs",
    path: "/aboutUs",
    component: () => import("/app/pages/aboutUs/index.vue")
  },
  {
    name: "master_category",
    path: "/catalog/category/:id()",
    meta: _91id_936Jasuu92smMeta || {},
    component: () => import("/app/pages/catalog/category/[id].vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/app/pages/catalog/index.vue")
  },
  {
    name: "portfolio",
    path: "/catalog/portfolio/:id()",
    meta: _91id_93OF54UEP2yxMeta || {},
    component: () => import("/app/pages/catalog/portfolio/[id].vue")
  },
  {
    name: "catalog-sub-category-id",
    path: "/catalog/sub-category-:id()",
    meta: sub_45category_45_91id_93lgdJWjOEugMeta || {},
    component: () => import("/app/pages/catalog/sub-category-[id].vue")
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    meta: coming_45soonp8168ES4xqMeta || {},
    component: () => import("/app/pages/coming-soon.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts/index.vue")
  },
  {
    name: "create_account",
    path: "/create-account",
    meta: indexUAMGQig0OAMeta || {},
    component: () => import("/app/pages/create-account/index.vue")
  },
  {
    name: "customer_office_chats",
    path: "/customer-office/chats",
    meta: chatsVSS4e6IWhCMeta || {},
    component: () => import("/app/pages/customer-office/chats.vue")
  },
  {
    name: "customer_office",
    path: "/customer-office",
    meta: indexVgdoxsv6GvMeta || {},
    component: () => import("/app/pages/customer-office/index.vue")
  },
  {
    name: "customer-office-orders-archive",
    path: "/customer-office/orders-archive",
    meta: orders_45archiveEVj7ITeu39Meta || {},
    component: () => import("/app/pages/customer-office/orders-archive.vue")
  },
  {
    name: profilemZNwzd5ZrrMeta?.name,
    path: "/customer-office/profile",
    meta: profilemZNwzd5ZrrMeta || {},
    component: () => import("/app/pages/customer-office/profile.vue"),
    children: [
  {
    name: "customer-office-profile",
    path: "",
    meta: indexdxOTKoq6k2Meta || {},
    component: () => import("/app/pages/customer-office/profile/index.vue")
  },
  {
    name: "customer-office-profile-notification-settings",
    path: "notification-settings",
    meta: notification_45settings8g1DKPU5SFMeta || {},
    component: () => import("/app/pages/customer-office/profile/notification-settings.vue")
  },
  {
    name: "customer-office-profile-privacy-security",
    path: "privacy-security",
    meta: privacy_45securityZy6UnyTb87Meta || {},
    component: () => import("/app/pages/customer-office/profile/privacy-security.vue")
  }
]
  },
  {
    name: "dev-enter",
    path: "/dev/enter",
    meta: enter73PTfrDZbyMeta || {},
    component: () => import("/app/pages/dev/enter.vue")
  },
  {
    name: "geo-test",
    path: "/geo-test",
    component: () => import("/app/pages/geo-test.vue")
  },
  {
    name: "faq_article",
    path: "/help/:id()/article/:article()",
    meta: _91article_93q3qbbdo3uPMeta || {},
    component: () => import("/app/pages/help/[id]/article/[article].vue")
  },
  {
    name: "faq_category",
    path: "/help/:id()",
    meta: index89kcWQtMUyMeta || {},
    component: () => import("/app/pages/help/[id]/index.vue")
  },
  {
    name: "faq",
    path: "/help",
    meta: indexjpdP4BPFKIMeta || {},
    component: () => import("/app/pages/help/index.vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "jobtoken",
    path: "/jobtoken",
    meta: index4swLnAVeKJMeta || {},
    component: () => import("/app/pages/jobtoken/index.vue")
  },
  {
    name: "master_office_chats",
    path: "/master-office/chats",
    meta: chatsjTXTOJh46IMeta || {},
    component: () => import("/app/pages/master-office/chats.vue")
  },
  {
    name: "master-office-correct-userdata",
    path: "/master-office/correct-userdata",
    meta: correct_45userdatad50zt5BoLLMeta || {},
    component: () => import("/app/pages/master-office/correct-userdata.vue")
  },
  {
    name: "master_office",
    path: "/master-office",
    meta: indexPzNdXdv9jKMeta || {},
    component: () => import("/app/pages/master-office/index.vue")
  },
  {
    name: "master_office_portfolio",
    path: "/master-office/portfolio",
    meta: portfoliomoaZtnZiCSMeta || {},
    component: () => import("/app/pages/master-office/portfolio.vue")
  },
  {
    name: profileHgy6J17bSdMeta?.name,
    path: "/master-office/profile",
    meta: profileHgy6J17bSdMeta || {},
    component: () => import("/app/pages/master-office/profile.vue"),
    children: [
  {
    name: "master-office-profile-billing",
    path: "billing",
    meta: billingHRicchvkV5Meta || {},
    component: () => import("/app/pages/master-office/profile/billing.vue")
  },
  {
    name: "master-office-profile",
    path: "",
    meta: indexPGVBpr34xJMeta || {},
    component: () => import("/app/pages/master-office/profile/index.vue")
  },
  {
    name: "master-office-profile-notification-settings",
    path: "notification-settings",
    meta: notification_45settingsUyUIzbqidtMeta || {},
    component: () => import("/app/pages/master-office/profile/notification-settings.vue")
  },
  {
    name: "master-office-profile-privacy-security",
    path: "privacy-security",
    meta: privacy_45securityUPrS9ye6PIMeta || {},
    component: () => import("/app/pages/master-office/profile/privacy-security.vue")
  }
]
  },
  {
    name: "master-office-promotion",
    path: "/master-office/promotion",
    meta: promotionhU7cJZwvwxMeta || {},
    component: () => import("/app/pages/master-office/promotion.vue")
  },
  {
    name: "master_office_task_archive",
    path: "/master-office/task-archive",
    meta: task_45archivex5Gx9AeYf5Meta || {},
    component: () => import("/app/pages/master-office/task-archive.vue")
  },
  {
    name: "master_office_task",
    path: "/master-office/task-search/:id()",
    meta: _91id_93ecKcpBg7JMMeta || {},
    component: () => import("/app/pages/master-office/task-search/[id].vue")
  },
  {
    name: "master-office-task-search-task_id",
    path: "/master-office/task-search/:task_id()",
    meta: _91task_id_93M5iBj4UWv5Meta || {},
    component: () => import("/app/pages/master-office/task-search/[task_id].vue")
  },
  {
    name: "master-office-task-search",
    path: "/master-office/task-search",
    meta: indexsrlcQYEUDEMeta || {},
    component: () => import("/app/pages/master-office/task-search/index.vue")
  },
  {
    name: "order_create",
    path: "/order-create",
    meta: order_45createv22DEFCmw9Meta || {},
    component: () => import("/app/pages/order-create.vue")
  },
  {
    name: "page-id",
    path: "/page/:id()",
    component: () => import("/app/pages/page/[id].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/pages/privacy-policy/index.vue")
  },
  {
    name: "promo-id",
    path: "/promo/:id()",
    meta: _91id_93nLfBx71BseMeta || {},
    component: () => import("/app/pages/promo/[id].vue")
  },
  {
    name: "promo",
    path: "/promo",
    meta: indexkwdd20Vk1dMeta || {},
    component: () => import("/app/pages/promo/index.vue")
  },
  {
    name: "promo-thankyou",
    path: "/promo/thankyou",
    meta: thankyouWry34NVec8Meta || {},
    component: () => import("/app/pages/promo/thankyou.vue")
  },
  {
    name: "task_category",
    path: "/tasks/category/:id()",
    meta: _91id_93PJQByBNvptMeta || {},
    component: () => import("/app/pages/tasks/category/[id].vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/app/pages/terms-of-service/index.vue")
  }
]